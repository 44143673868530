.bar {
  animation: bounce 0.5s ease-in-out infinite alternate;
  transform-origin: bottom; /* Anchor scaling from bottom */
}

.bar1 {
  animation-delay: 0.1s;
}

.bar2 {
  animation-delay: 0.2s;
}

.bar3 {
  animation-delay: 0.3s;
}

.bar4 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: scaleY(0.2); /* Smallest scale */
  }
  to {
    transform: scaleY(1); /* Full height */
  }
}
