.glowbtn a {
    position: relative;
    /* color: #03e9f4; */
    color: #33f403;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2.5px;
    overflow: hidden;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid transparent;
    margin: 20px;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    backdrop-filter: blur(10px);
    cursor: pointer;
}

.glowbtn a:hover {
    background: #03e9f4;
    color: #050801;
    /* box-shadow: 0 0 5px #03e9f4, - good Color Make change */
    box-shadow: 0 0 5px #33f403,
                0 0 25px #33f403,
                0 0 50px #33f403,
                0 0 200px #33f403;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.glowbtn a span {
    position: absolute;
    display: block;
}

.glowbtn a span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    /* background: linear-gradient(90deg, transparent, #03e9f4); */
    background: linear-gradient(180deg, transparent, #33f403);
    animation: animate1 1s linear infinite;
}

@keyframes animate1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

.glowbtn a span:nth-child(2) {
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #33f403);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}

@keyframes animate2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

.glowbtn a span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #33f403);
    animation: animate3 1s linear infinite;
    animation-delay: 0.50s;
}

@keyframes animate3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

.glowbtn a span:nth-child(4) {
    bottom: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #33f403);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}
