#nav-icon2 {
  width: 32px;
  height: 16px;
  position: relative;
  margin: 10px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: white;
  opacity: 1;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 6px 6px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0;
  border-radius: 6px 0 0 6px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 12px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 24px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 3px;
  top: 5px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 3px);
  top: 5px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 3px;
  top: 21px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 3px);
  top: 21px;
}
