@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none; border: none;
  transition: all .2s linear;
}
html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body{
  background: #f7f7f7;
  font-family: 'Poppins', sans-serif;
}

*::selection{
  background: #2b3dda;
  color: #fff;
}
html{
  font-size: 62.5%;
  overflow-x: hidden;
}
html::-webkit-scrollbar{
  width: .8rem;
}
html::-webkit-scrollbar-track{
  background: rgb(235, 202, 245);
}
html::-webkit-scrollbar-thumb{
  background: #420177;
}
/* navbar starts */
header{
  position: fixed;
  top: 0; left: 0; right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem 10%;
  height: 6.5rem;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(146,161,176,.3);
}
section{
  min-height: 100vh;
  padding: 2rem 9%;
}
header .logo{
  font-size: 2rem;
  font-weight: 800;
  margin-left: -50px;
  text-decoration: none;
  color: #0E2431;
}
header .logo i {
  font-size: 2.2rem;
}
header .logo:hover {
  color: #fc8c05;
}
header .navbar ul{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .navbar li{
  margin-left: 2.5rem;
}
header .navbar ul li a{
  font-size: 1.57rem;
  color: #0E2431;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.04rem;
}
header .navbar ul li a.active,
header .navbar ul li a:hover{
  color: #011aff;
  border-bottom: .2rem solid #011aff;
  padding: .5rem 0;
}
/* navbar ends */

/* hamburger icon starts*/
#menu{
  font-size: 3rem;
  cursor: pointer;
  color: rgb(24, 2, 63);
  display: none;
}
@media(max-width:768px){
  #menu{
      display: block;
      background: transparent;
  }
  header .navbar{
    position: fixed;
    top: 6.5rem; right: -120%;
    width: 50%;
    height: 100%;
    text-align: left;
    align-items: flex-start;
    /* background-color: #0e0f31; */
    /* background-color: transparent; */
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    backdrop-filter: blur(15px); /* Apply blur effect */
    -webkit-backdrop-filter: blur(12px); /* For Safari */
  }
  header .navbar ul{
    flex-flow: column;
    padding: 1rem;
  }
  header .navbar ul li{
    text-align: center;
    width: 100%;
    margin: 1rem 0;
    border-radius: .5rem;
    width: 26rem;
  }
  header .navbar ul li a{
    display: block;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    /* color: #ff0000; */
    color: #ff6200;
    font-size: 2rem;
  }
  header .navbar ul li a.active,
  header .navbar ul li a:hover{
    padding: 1rem;
    color: #fff;
    border-radius: .5rem;
    border-bottom: .5rem solid #011aff;
  }
  .fa-times{
    transform: rotate(180deg);
  }
  header .navbar.nav-toggle{
    right: 0;
  }
}
/* hamburger icon ends */

/* 404 section start */
.page_404{ 
    padding:20px 0; 
    background:rgb(255, 255, 255);
    margin-top: 6rem;
    min-height: 60vh;
}
.wrapper{
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}
.msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.msg h3{
    font-size: 4rem;
    font-weight: 700;
}
.msg p{
    font-size: 1.5rem;
    font-weight: 600;
}
.wrapper h1{
  font-size: 88px;
  text-align: center;
}

/* back button */
.backbtn{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.backbtn .btn {
  position: relative;
  line-height: 0;
  padding: 1.6rem 3rem;
  border-radius: .5em;
  transition: 0.5s;
  color: #fff;
  background: #2506ad;
  box-shadow: 0px 5px 10px rgba(48, 68, 247, .6);
  text-align: center;
}
.backbtn .btn span {
  font-weight: 600;
  font-size: 1.7rem;
  font-family: 'Nunito', sans-serif;
}
.backbtn .btn i {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  transition: 0.3s;
}
.backbtn .btn:hover{
  background: #1a047e;
}
.backbtn .btn:hover i {
  transform: translateX(-8px);
}
/* 404 section end */

/* footer section starts */
.footer{
  min-height: auto;
  padding-top: 0;
  background: rgb(0, 1, 43);
}
.footer .box-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.footer .box-container .box{
  flex: 1 1 25rem;
  margin: 2rem;
}
.footer .box-container .box h3{
  font-size: 2.5rem;
  color: #fff;
  padding-bottom: 1rem;
  font-weight: normal;
}
.footer .box-container .box p{
  font-size: 1.5rem;
  color: #ccc;
  padding: .7rem 0;
  text-transform: none;
}
.footer .box-container .box p i{
  padding-right: 1rem;
  color: #ffae00;
}
.footer .box-container .box a{
  font-size: 1.5rem;
  color: rgb(238, 238, 238);
  padding: .3rem 0;
  display: block;
}
.footer .box-container .box a:hover{
  color: #ffae00;
}
.footer .box-container .box .share{
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
}
.footer .box-container .box .share a{
  height: 4rem;
  width: 4rem;
  padding: 1rem;
  text-align: center;
  border-radius: 5rem;
  font-size: 1.7rem;
  margin-right: 1rem;
  transition: .2s;
  background: rgb(230, 230, 230);
  color: #02094b;
  border: none;
}
.footer .box-container .box .share a:hover{
  background: transparent;
  transform: scale(0.98);
  border: .1rem solid rgb(180, 178, 178);
  color: #ffae00;
}
.footer .credit{
  padding: 1rem 0 0 0;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Nunito',sans-serif;
  font-weight: 600;
  color: #fff;
  border-top: .1rem solid #fff3;
}
.footer .credit a{
  color: #ffae00;
}
.footer .fa{
    color: #E90606;
    margin: 0 .3rem;
    font-size: 1.5rem;
    animation: pound .35s infinite alternate;
}
@-webkit-keyframes pound{
  to{
    transform: scale(1.1);
  }
}
@keyframes pound{
  to{
    transform: scale(1.1);
}
}
@media(max-width:450px){
  .footer .box-container .box{
    margin: 2rem;
  }
  .footer .box-container .box p{
    padding: 0.7rem;
  }
  .footer .box-container .box .share a{
    padding: 1.1rem;
  }
  .page_404{ 
    padding: 20px 5px; 
    margin-top: 6.5rem;
  }
  .wrapper{
    height: 430px;
  }
  .msg h3{
      font-size: 3.2rem;
  }
  .msg p{
      font-size: 1.24rem;
      font-weight: 600;
  }
  .wrapper h1{
    font-size: 70px;
    text-align: center;
  }
  header .logo{
    font-size: 2.2rem;
    margin-left: -30px;
    margin-right: -40px;
  }
}
/* footer section ends */

/* common media queries starts*/
@media(max-width:450px){
  html{
    font-size: 55%;
  }
  body{
    padding-right: 0;
  }
  section{
    padding: 2rem;
  }
}
/* common media queries ends*/


/* scroll top starts */
#scroll-top{
  position: fixed;
  top: -140%;
  right: 2rem;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  background: #ffae00;
  color: rgb(13, 0, 44);
  border-radius: 5rem;
  transition: 1s linear;
  z-index: 1000;
}
#scroll-top.active{
  top: calc(100% - 12rem);
}
/* scroll top ends */
