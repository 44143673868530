.myheader {
  color: white;
  padding: 4px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  margin-bottom: -20px;
}

.myheader:hover {
  opacity: 1;
}

.mobile-navbar {
  display: none;
}

.logoheader {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding-left: 8px;
  margin-top: 4px;
  background: transparent;
  color: white;
}

.logoheader img {
  height: 35px;
  background: transparent;
}

.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.hamburger-icon {
  display: none;
}


@media screen and (max-width: 798px) {
  .hamburger-icon {
    display: block;
    z-index: 3;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 18px;
    margin-top: 5px;
    padding: 0;
    font-size: 16px;
  }

  .hamburger-icon svg {
    width: 24px; /* Set explicit width */
    height: 24px; /* Set explicit height */
  }
  
  .mobile-navbar.open {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .mobile-navbar.open ul {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-navbar.open ul li a {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 250px;
    height: 48px;
    border-radius: 16px;
    background-color: wheat;
    color: black;
  }
  .mobile-navbar.open ul li .nav-resume-link {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 250px;
    height: 50px;
    border-radius: 15px;
    background-color: #1a1a1a;
    color: wheat;
  }
  .mobile-navbar.open ul li .nav-resume-link:hover {
    border: 1px solid wheat;
  }
  .mobile-navbar.open div {
    display: none;
    display: inline-flex;
    width: 0;
    margin: 10px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    animation: revSlideIn 1s forwards;
  }
  .mobile-navbar.open ul li .nav-resume-link div {
    width: auto;
    animation: revSlideIn 1s forwards;
  }

  .head-container .logoheader {
    font-size: 1.8rem;
    font-weight: 500;
    padding-left: 7px;
    margin-top: 4px;
    display: block;
    background: transparent;
  }

  .head-container {
    background: transparent;
    height: 5.5rem;
  }
  .head-container .hamburger-icon{
    font-size: 10px;
  }

  @keyframes revSlideIn {
    from {
      width: 0;
    }
    to {
      width: 70%;
    }
  }

  .resume-link-li {
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 250px;
    height: 50px;
    border-radius: 15px;
    background-color: #1a1a1a;
    color: wheat;
  }
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 20px;  
    margin-bottom: 10px; 
    color: #000;  
    font-weight: bold; 
    font-size: 15px;  
  }
  
  .nav-icon {
    margin-left: 65px;
  }
  
  .mobile-navbar.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .nav-item a {
    text-decoration: none;
    color: inherit;
  }