.aboutPC {
  background: #010215;
  padding: 2rem 2rem;
}

.aboutPC .row {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  flex-wrap: wrap;
}

.aboutPC .row .image {
  text-align: left;
  flex: 1 1 26rem;
}

.aboutPC .row .image img {
  margin: 2.5rem 0;
  width: 35rem;
  height: 46.5rem;
  border-radius: 15%;
  margin-left: 15%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.aboutPC .row .content {
  flex: 1 1 45rem;
  padding: 1.2rem;
  background: transparent;
  border-radius: 10px;
  height: 580px;
  margin-top: -1%;
  margin-right: 4%;
  transition: border-color 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.aboutPC .row .content p {
  font-size: 1.62rem;
  color: #ffffff;
  margin-top: 1.6rem;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

.aboutPC .row .content .box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
}

.aboutPC .row .content .box-container .box p {
  text-transform: none;
}

.glowbtnAbt a {
  position: relative;
  color: #f403e8;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 2.5px;
  overflow: hidden;
  display: inline-block;
  padding: 15px 30px;
  border: 2px solid transparent;
  margin: 20px;
  font-size: 18px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.glowbtnAbt a:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #f403e8,
              0 0 25px #f403e8,
              0 0 50px #f403e8,
              0 0 200px #f403e8;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.glowbtnAbt a span {
  position: absolute;
  display: block;
}

.glowbtnAbt a span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(180deg, transparent, #f403e8);
  animation: animate1 1s linear infinite;
}

@keyframes animate1 {
  0% {
      left: -100%;
  }
  50%, 100% {
      left: 100%;
  }
}

.glowbtnAbt a span:nth-child(2) {
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #f403e8);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes animate2 {
  0% {
      top: -100%;
  }
  50%, 100% {
      top: 100%;
  }
}

.glowbtnAbt a span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f403e8);
  animation: animate3 1s linear infinite;
  animation-delay: 0.50s;
}

@keyframes animate3 {
  0% {
      right: -100%;
  }
  50%, 100% {
      right: 100%;
  }
}

.glowbtnAbt a span:nth-child(4) {
  bottom: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f403e8);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes animate4 {
  0% {
      bottom: -100%;
  }
  50%, 100% {
      bottom: 100%;
  }
}
